import { CancelToken } from 'axios';
import qs from 'qs';
import config from '../../constants/serverConfig';
import api from '../../utils/api';
import { saveAs } from 'file-saver'; 

import { actionShowToast } from '../../reduxModules/common/toast';

import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';
import {
  GET_EVENTS_LIST,
  SET_ACTIVE_EVENT,
  RESET_ACTIVE_EVENT,
  GET_MORE_EVENTS_LIST,
  GET_EVENT_DETAILS,
  OPEN_NEW_EVENT_MODAL,
  OPEN_UPDATE_EVENT_MODAL,
  CLOSE_NEW_EVENT_MODAL,
  POST_EVENT,
  PUT_EVENT,
  DELETE_EVENT,
  CANCEL_FETCH_EVENTS,
  SET_ACTIVE_EVENT_SESSION,
  RESET_ACTIVE_EVENT_SESSION,
  SEARCH_TEAM_EMPLOYEE,
  FETCH_ATTENDEES,
  FETCH_MORE_ATTENDEES,
  FETCH_MORE_ATTENDED_LIST,
  DELETE_SEARCH_EMPLOYEE,
  DELETE_SEARCH_TEAM,
  SET_EVENT_GRID_VIEW,
  EXPORT_ATTENDEE_LIST,
  EXPORT_ATTENDED_LIST,
  FETCH_ATTENDED_LIST,
  FETCH_DEFAULT_BANNER,
  IS_FEATURE,
  FETCH_COMMITMENTS_BY_TEAM_PER_SESSION,
  EXPORT_EVENT_CSV,
  VERIFY_EVENT,
  RESET_ATTENDEE_LIST
} from './index';
import isEmpty from '../../utils/isEmpty';
let source = CancelToken.source();

export const actionFetchEvents = (count = 10, search = '', month = '', year ='') => async dispatch => {
  try{
    dispatch({ type: GET_EVENTS_LIST + PENDING});
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/events?page=1&rows=${count}&search=${encodeURIComponent(search)}&month=${month}&year=${year}`,
      cancelToken: source.token
    })

    const eventsList = data.data.events
    const total = data.data._metadata.totalCount
    dispatch({ type: GET_EVENTS_LIST + SUCCESS, payload: { data: eventsList, total }});
   

  } catch (e) {
    
    if(!e.isCancel){
      dispatch({ type: GET_EVENTS_LIST + ERROR, e});
    }
  }
};

export const actionFetchEventDetails = (eventId) => async (dispatch, getState) => {
  if (getState().location.payload.id) eventId = getState().location.payload.id;

  try {
    dispatch({ type: GET_EVENT_DETAILS + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/events/info/${eventId}`,
      cancelToken: source.token
    })

    if (!isEmpty(data)) {
      dispatch({ type: GET_EVENT_DETAILS + SUCCESS, payload: data });
    } else {
      dispatch({ type: GET_EVENT_DETAILS + ERROR });
    }

  } catch (e) {
    dispatch({ type: GET_EVENT_DETAILS + ERROR, e });
  }
};

export const actionFetchMoreEvents = (page, count = 10, search = '', month = '', year = '') => async dispatch => {
  try {
    dispatch({ type: GET_MORE_EVENTS_LIST + PENDING });
    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/events?rows=${count}&page=${page}&search=${encodeURIComponent(search)}&month=${month}&year=${year}`,
      cancelToken: source.token
    });
    
    if (!isEmpty(data.data.events)) {
      const eventsList = data.data.events
      dispatch({ type: GET_MORE_EVENTS_LIST + SUCCESS, payload: { data: eventsList } });
    } else {
      dispatch({ type: GET_MORE_EVENTS_LIST + ERROR });
    }
  } catch(e) {
    dispatch({ type: GET_MORE_EVENTS_LIST + ERROR, e });
  }
}

export const actionSetActiveEvent = eventId => ({
  type: SET_ACTIVE_EVENT,
  payload: eventId
});

export const actionResetActiveEvent = () => ({
  type: RESET_ACTIVE_EVENT
});

export const actionSetActiveEventSession = eventSessionId => ({
  type: SET_ACTIVE_EVENT_SESSION,
  payload: eventSessionId
});

export const actionResetActiveEventSession = () => ({
  type: RESET_ACTIVE_EVENT_SESSION
});

export const actionOpenNewEventModal = (eventId) => dispatch => {
  console.log('!!! actionOpenNewEventModal', eventId);
  if (eventId) {
    dispatch(actionFetchEventDetails(eventId));
    dispatch({
      type: OPEN_UPDATE_EVENT_MODAL
    })
  } else {
    dispatch({
      type: OPEN_NEW_EVENT_MODAL
    })
  }
}

export const actionCloseNewEventModal = props => dispatch => dispatch({
  type: CLOSE_NEW_EVENT_MODAL
});

export const actionSubmitNewEvent = (eventData, newBannerImg, newEventDocument) => async dispatch => {

  try {
    dispatch({ type: POST_EVENT + PENDING });

    const { data } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/events`,
      // cancelToken: source.token,
      data: eventData
    });

    const eventID = data.data.eventId;
    if ((!isEmpty(newBannerImg.img) || !isEmpty(newEventDocument.document)) && !isEmpty(eventID)) {
      let formData = new FormData();
      formData.append('eventId', eventID);
      formData.append('eventType', eventData.type);

      if (!isEmpty(newBannerImg.img)) {
        formData.append('bannerUrl', newBannerImg.img);
      }

      if (!isEmpty(newEventDocument.document)){
        formData.append('pdfFile', newEventDocument.document);
      }

      try {
        await api({
          method: 'POST',
          url: `${config.SERVER_BASE_URL}/events/upload-event-file`,
          cancelToken: source.token,
          headers: {
            'Content-type': 'multipart/form-data'
          },
          data: formData
        });
      } catch (e) {
        dispatch({ type: POST_EVENT + ERROR });
        await dispatch(actionDeleteEventCard(eventID))
        return false;
      }
      
    }

    dispatch({ type: POST_EVENT + SUCCESS });
    return true;
  } catch (e) {
    dispatch({ type: POST_EVENT + ERROR });
    return false;
  }
}

export const actionSubmitUpdateEvent = (eventData, newBannerImg, newEventDocument) => async dispatch => {

  try {
    dispatch({ type: PUT_EVENT + PENDING });

    await api({
      method: 'PUT',
      url: `${config.SERVER_BASE_URL}/events/${eventData.id}`,
      // cancelToken: source.token,
      data: eventData
    });

    if ((!isEmpty(newBannerImg.img) || !isEmpty(newEventDocument.document)) && !isEmpty(eventData.id)) {
      let formData = new FormData();
      formData.append('eventId', eventData.id);
      formData.append('eventType', eventData.type);
      if (!isEmpty(newBannerImg.img)) {
        formData.append('bannerUrl', newBannerImg.img);
      }

      if (!isEmpty(newEventDocument.document)) {
        formData.append('pdfFile', newEventDocument.document);
      }

      try {
        await api({
          method: 'POST',
          url: `${config.SERVER_BASE_URL}/events/upload-event-file`,
          cancelToken: source.token,
          headers: {
            'Content-type': 'multipart/form-data'
          },
          data: formData
        });
      } catch (e) {
        dispatch({
          type: PUT_EVENT + ERROR
        });
        await dispatch(actionDeleteEventCard(eventData.id))
        return false;
      } 
    }
    dispatch({ type: PUT_EVENT + SUCCESS });
    return true;
  } catch (e) {
    dispatch({ type: PUT_EVENT + ERROR });
    return false;
  }
}

export const actionDeleteEventCard = id => async dispatch => {  
  try {

    dispatch({ type: DELETE_EVENT + PENDING });
    source = CancelToken.source();

    await api({
      method: 'DELETE',
      url: `${config.SERVER_BASE_URL}/events`,
      data: {
        ids: [id]
      },
      cancelToken: source.token
    });

    dispatch({ type: DELETE_EVENT + SUCCESS, payload: { data: id } });

  } catch (e) {
    dispatch({ type: DELETE_EVENT + ERROR, e });
  }
}

export const actionFetchTeamAndEmployee = (search = '', employees = [], teams =[]) => async dispatch => {
  try {

    dispatch({ type: SEARCH_TEAM_EMPLOYEE + PENDING });
    source = CancelToken.source();

    let queryParams = {
      search,
      employees: employees.join(','),
      teams: teams.join(',')
    }

    let queryString = qs.stringify(queryParams);

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/accounts/search?${queryString}`,
      cancelToken: source.token
    });
    
    dispatch({ type: SEARCH_TEAM_EMPLOYEE + SUCCESS, payload: { data: data.data } });

  } catch (e) {
    dispatch({ type: SEARCH_TEAM_EMPLOYEE + ERROR, e });
  }
}

export const actionCancelFetchEvents = () => dispatch => {
  source.cancel();
  source = CancelToken.source();

  dispatch({
    type: CANCEL_FETCH_EVENTS
  });
};

export const actionCancelFetchTeamAndEmployee = () => dispatch => {
  source.cancel();
  source = CancelToken.source();

  dispatch({
    type: CANCEL_FETCH_EVENTS
  });
};

export const actionFetchAttendees = (eventId ='' , sessionId='', page = 1, row = 20, type = '' ) => async dispatch => {
  try {
    console.log("actionFetchAttendees Called!!!");
    dispatch({ type: FETCH_ATTENDEES  + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/events/attendees/${eventId}/${sessionId}?page=${page}&row=${row}&type=${type}`,
      cancelToken: source.token
    });
    const { _metadata, employees } = data.data;

    let totalCount = 0;
    let totalPages = 0;

    if (!isEmpty(_metadata)) {
      totalCount = _metadata.totalCount;
      totalPages = _metadata.totalPages;
    }

    dispatch({ type: FETCH_ATTENDEES  + SUCCESS, payload: { data: employees, totalCount , totalPages} });

  } catch (e) {
    dispatch({ type: FETCH_ATTENDEES  + ERROR, e });
    console.log(e)
  }
}


export const actionFetchAttendedList = (eventId = '', sessionId = '', page = 1, row = 20 ) => async dispatch => {
  try {
    console.log("actionFetchAttendedList Called!!!");
    dispatch({ type: FETCH_ATTENDED_LIST + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/attendance/${eventId}/${sessionId}?page=${page}&row=${row}`,
      cancelToken: source.token
    });
    const { _metadata, attendanceList } = data.data;

    let totalCount = 0;
    let totalPages = 0;

    if (!isEmpty(_metadata)) {
      totalCount = _metadata.totalCount;
      totalPages = _metadata.totalPages;
    }

    dispatch({ type: FETCH_ATTENDED_LIST + SUCCESS, payload: { data: attendanceList, totalCount, totalPages } });

  } catch (e) {
    dispatch({ type: FETCH_ATTENDED_LIST + ERROR, e });
    console.log(e)
  }
}


export const actionFetchMoreAttendedList = (eventId = '', sessionId = '', page = 1, row = 20) => async dispatch => {
  try {
    console.log("actionFetchMoreAttendedList Called!!!");
    dispatch({ type: FETCH_MORE_ATTENDED_LIST + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/attendance/${eventId}/${sessionId}?page=${page}&row=${row}`,
      cancelToken: source.token
    });
    const { _metadata, attendanceList } = data.data;

    let totalCount = 0;
    let totalPages = 0;

    if (!isEmpty(_metadata)) {
      totalCount = _metadata.totalCount;
      totalPages = _metadata.totalPages;
    }

    dispatch({ type: FETCH_MORE_ATTENDED_LIST + SUCCESS, payload: { data: attendanceList, totalCount, totalPages } });

  } catch (e) {
    dispatch({ type: FETCH_MORE_ATTENDED_LIST + ERROR, e });
    console.log(e)
  }
}

export const actionFetchMoreAttendees = (eventId = '', sessionId = '', page = 1, row = 20, type = '' ) => async dispatch => {
  try {

    dispatch({ type:FETCH_MORE_ATTENDEES + PENDING });
    source = CancelToken.source();
    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/events/attendees/${eventId}/${sessionId}?page=${page}&row=${row}&type=${type}`,
      cancelToken: source.token
    });

    const { employees } = data.data;


    if (!isEmpty(employees)) {
      dispatch({ type: FETCH_MORE_ATTENDEES + SUCCESS, payload: { data: employees } });
    } else {
      dispatch({ type: FETCH_MORE_ATTENDEES + ERROR });
    }

  } catch (e) {
    dispatch({ type: FETCH_MORE_ATTENDEES + ERROR, e });
  }
}

export const actionFetchCommitmentsByTeamPerSession = (eventId = '', sessions = []) => async dispatch => {
  try {

    dispatch({ type: FETCH_COMMITMENTS_BY_TEAM_PER_SESSION + PENDING });
    source = CancelToken.source();
    const sessionArr  = [];

    await Promise.all(sessions.map( async (session) => {
      const { data } = await api({
        method: 'GET',
        url: `${config.SERVER_BASE_URL}/events/dashboard/${eventId}/${session.sessionId}`,
        cancelToken: source.token
      });
      const { teams } = data.data;
      sessionArr.push(teams);
    })
    )

    if (!isEmpty(sessionArr)) {
      dispatch({ type: FETCH_COMMITMENTS_BY_TEAM_PER_SESSION + SUCCESS, payload: { data: sessionArr } });
    } else {
      dispatch({ type: FETCH_COMMITMENTS_BY_TEAM_PER_SESSION + ERROR });
    }

  } catch (e) {
    dispatch({ type: FETCH_MORE_ATTENDEES + ERROR, e });
  }
}


export const actionDeleteEmployeeSearch = id => ({
  type: DELETE_SEARCH_EMPLOYEE,
  payload: { id }
});
export const actionDeleteTeamSearch = id => ({
  type: DELETE_SEARCH_TEAM,
  payload: { id }
});

export const actionSetEventGridView = isGrid => async dispatch => {
  try {
    dispatch({ type: SET_EVENT_GRID_VIEW, payload: { data: isGrid } })
  } catch (e) {
    dispatch({ type: SET_EVENT_GRID_VIEW + ERROR, e });
  }
}



export const actionExportAttendeeListCSV = (eventId = '', sessionId ='', type = 0, headerText = '') => async dispatch => {
  try {
    dispatch({ type: EXPORT_ATTENDEE_LIST + PENDING });
    source = CancelToken.source();
    let apiURL = headerText === 'Attended' ?
      `${config.SERVER_BASE_URL}/attendance/export/${eventId}/${sessionId}`
      :
      `${config.SERVER_BASE_URL}/events/joining-export?eventId=${eventId}&sessionId=${sessionId}&toExport=${type}`
    
    const { data } = await api({
      method: 'GET',
      url: apiURL,
      cancelToken: source.token
    });
    const  csvFile  = data.data;  

    if (!isEmpty(csvFile)) {
      //dispatch({ type: EXPORT_ATTENDEE_LIST + SUCCESS, payload: { data: csvFile} });
      saveAs(csvFile, csvFile);
    } else {
      dispatch({ type: EXPORT_ATTENDEE_LIST + ERROR });
    }

  } catch (e) {
    dispatch({ type: EXPORT_ATTENDEE_LIST + ERROR, e });
  }
};


export const actionExportAttendedListCSV = (eventId = '', sessionId = '', teamId = '', page = 1, row=20) => async dispatch => {
  try {
    dispatch({ type: EXPORT_ATTENDEE_LIST + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/attendance/export/eventId=${eventId}&sessionId=${sessionId}&teamId=${teamId}&page=${page}&row=${row}`,
      cancelToken: source.token
    });
    const csvFile = data.data;

    if (!isEmpty(csvFile)) {
      //dispatch({ type: EXPORT_ATTENDEE_LIST + SUCCESS, payload: { data: csvFile} });
      saveAs(csvFile, csvFile);
    } else {
      dispatch({ type: EXPORT_ATTENDEE_LIST + ERROR });
    }

  } catch (e) {
    dispatch({ type: EXPORT_ATTENDEE_LIST + ERROR, e });
  }
};

export const actionGetDefaultBanner = () => async dispatch => {
  try {
 
    dispatch({ type: FETCH_DEFAULT_BANNER + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/banners/suggested`,
      cancelToken: source.token
    });
    const  {suggestedBanners}  = data.data;  

    dispatch({ type: FETCH_DEFAULT_BANNER + SUCCESS, payload: { data: suggestedBanners } });
    

  } catch (e) {
    dispatch({ type: FETCH_DEFAULT_BANNER + ERROR, e });
  }
};
export const actionSetIsFeature = (eventId = '', isFeature = '') => async (dispatch, getState) => {
  if(getState().appEvents.isFeaturePending) return false;
  try{
    dispatch({ type: IS_FEATURE + PENDING });
    source = CancelToken.source
    const { data } = await api({
      method: 'PUT',
      url: `${config.SERVER_BASE_URL}/events/feature/${eventId}`,
      cancelToken: source.token,
      data :
      {
        "isFeature" : !isFeature, // true or false
      }
    });
    dispatch({ type: IS_FEATURE + SUCCESS, payload: { data : { isFeature: isFeature, eventId: eventId } } })
  } catch (e) {
    console.log(e)
    dispatch({ type: IS_FEATURE + ERROR, e })
  }
};


export const actionExportCSV = (month = '', year = '') => async dispatch => {
  try {

    if(isEmpty(month) || isEmpty(year)){
      const currDate = new Date()
      month = currDate.getMonth() + 1;
      year = currDate.getFullYear();
    }

    dispatch({ type: EXPORT_EVENT_CSV + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/events/export?month=${month}&year=${year}`,
      cancelToken: source.token
    });

    const csvFile = data.data;

    if (!isEmpty(csvFile)) {
      saveAs(csvFile, csvFile);
    }

    dispatch({ type: EXPORT_EVENT_CSV + SUCCESS });


  } catch (e) {
    dispatch({ type: EXPORT_EVENT_CSV + ERROR, e });
  }
};
export const actionVerifyEvent = (eventId = '') => async dispatch => {
  try{
    dispatch({ type: VERIFY_EVENT + PENDING });
    source = CancelToken.source

    await api({
      method: 'PUT',
      url: `${config.SERVER_BASE_URL}/attendance/validate/points/${eventId}`,
      cancelToken: source.token
    });

    dispatch(actionShowToast({
      message: 'Event attendance has been verified successfully.',
      buttonText: 'Close'
    }))
    dispatch({ type: VERIFY_EVENT + SUCCESS })
  } catch (e) {
    dispatch({ type: VERIFY_EVENT + ERROR, e })
  }
}

export const actionResetVerify = () => ({
  type: VERIFY_EVENT + SUCCESS
})

export const actionResetAttendeeList = () => ({
  type: RESET_ATTENDEE_LIST
})
