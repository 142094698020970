import { GET_VOES_LIST, UPDATE_READ_UNREAD, GET_MORE_VOES_LIST } from "./index";
import update from 'immutability-helper';
import { PENDING, SUCCESS, ERROR } from "../../constants/statusTypes";

const initialState = {
  voesList: [],
  totalVoes: 0,

  fetchVoeListPending: false,
  fetchVoeListError: false,
  fetchMoreVoeListPending: false,
  fetchMoreVoeListError: false,
};

const voes = (state = initialState, action = {}) => {
  switch (action.type) {
  case GET_VOES_LIST + PENDING:
    return {
      ...state,
      fetchVoeListPending: true,
      fetchVoeListError: false,
    };
  case GET_VOES_LIST + SUCCESS:
    return {
      ...state,
      voesList: action.payload.data,
      totalVoes: action.payload.total,
      fetchVoeListPending: false,
      fetchVoeListError: false,
    };
  case GET_VOES_LIST + ERROR:
    return {
      ...state,
      fetchVoeListPending: false,
      fetchVoeListError: true,
    };
  //Update VOE Read and Unread
  case UPDATE_READ_UNREAD + PENDING:
    return {
      ...state,
      fetchVoeListPending: true,
      fetchVoeListError: false,
    };
  case UPDATE_READ_UNREAD + SUCCESS:
    return {
      ...state,
      voesList: action.payload.data,
      totalVoes: action.payload.total,
      fetchVoeListPending: false,
      fetchVoeListError: false,
    };
  case UPDATE_READ_UNREAD + ERROR:
    return {
      ...state,
      fetchVoeListPending: false,
      fetchVoeListError: true,
    };
    
  case GET_MORE_VOES_LIST + PENDING:
    return {
      ...state,
      fetchMoreVoeListPending: true,
      fetchMoreVoeListError: false,
    };
  case GET_MORE_VOES_LIST + SUCCESS:
    return update(
      state,
      {
        voesList: { $push: action.payload.data },
        fetchMoreVoeListPending: { $set: false },
        fetchMoreVoeListError: { $set: false }
      }
    );  
  case GET_MORE_VOES_LIST + ERROR:
    return {
      ...state,
      fetchMoreVoeListPending: false,
      fetchMoreVoeListError: true,
    };
  default:
    return state;
  }    
};

export default voes;
